import { reactive } from "vue";

export type VisitedPathType = {
  count: number;
  path: string;
  title: string;
  datetime: number;
  isPinned?: boolean;
};

export type FavoritePagesType = {
  path: string;
  title: string;
};

export const visitedStore = reactive({
  visitedPath: (JSON.parse(localStorage.getItem("visitedInfo") || "[]") ||
    []) as VisitedPathType[],

  favoritePath: (JSON.parse(localStorage.getItem("favoriteUrls") || "[]") ||
    []) as FavoritePagesType[],

  visitRoute(url: string, title: string) {
    if (url.includes("visited-info") || url === "/") {
      return;
    }
    const isExisted = this.visitedPath.find((i) => i.path === url);
    if (isExisted) {
      this.visitedPath = this.visitedPath.map((i) => {
        if (i.path === url) {
          return {
            count: i.count + 1,
            path: i.path,
            title,
            datetime: Date.now(),
          };
        }
        return i;
      });
    } else {
      const newInfo = {
        count: 1,
        path: url,
        title,
        datetime: Date.now(),
      };

      this.visitedPath.push(newInfo);
    }

    localStorage.setItem("visitedInfo", JSON.stringify(this.visitedPath));
  },

  favoriteRoute(url: string, title: string) {
    const isFavoriteIndex = this.favoritePath.findIndex(
      (item) => item.path === url,
    );
    if (isFavoriteIndex !== -1) this.favoritePath.splice(isFavoriteIndex, 1);
    else
      this.favoritePath.unshift({
        path: url,
        title,
      });
    localStorage.setItem("favoriteUrls", JSON.stringify(this.favoritePath));
  },
});
