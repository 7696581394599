import { createI18n } from "vue-i18n";
import de from "~/locales/de.json";
import en from "~/locales/en.json";
import es from "~/locales/es.json";
import it from "~/locales/it.json";

export default createI18n<false>({
  legacy: false,
  globalInjection: true,
  locale: "en",
  messages: { en, de, es, it },
});
