import type {
  MailerRecipients_backlinkType,
  MailerRecipients_status,
  Backlink_status,
  QueueStatus,
} from "@prisma/client";
import { z } from "zod";
import i18n from "~/utils/i18n";
const { t } = i18n.global;

export const uiLanguageSchema = z.enum(["de", "en"]);
export type UiLanguage = z.infer<typeof uiLanguageSchema>;
export function uiLanguages(): Record<UiLanguage, string> {
  return {
    de: t("generic.languages.de"),
    en: t("generic.languages.en"),
  };
}
export function uiLanguagesArray(): { value: UiLanguage; name: string }[] {
  return [
    { value: "de", name: uiLanguages().de },
    { value: "en", name: uiLanguages().en },
  ];
}

export const promptLanguageSchema = z.enum(["de", "en", "fr", "it", "es"]);
export type PromptLanguage = z.infer<typeof promptLanguageSchema>;
export const promptLanguages: () => {
  [key in PromptLanguage]: string;
} = () => ({
  de: t("generic.languages.de"),
  en: t("generic.languages.en"),
  fr: t("generic.languages.fr"),
  it: t("generic.languages.it"),
  es: t("generic.languages.es"),
});

export const trackingEntryStatusOptions: [
  MailerRecipients_status,
  ...MailerRecipients_status[],
] = [
  "Ready",
  "Contacted",
  "Rejected",
  "Forwarded",
  "InNegotiation",
  "Offer",
  "OfferConfirmed",
  "Live",
  "Lost",
  "Cooperation",
  "Postponed",
  "FollowUp",
  "OfferArchived",
  "NotInterested",
  "Problem",
  "Invalid",
  "Blacklist",
];

export const allStatusOptions: {
  [k in MailerRecipients_status]: MailerRecipients_status;
} = {
  Ready: "Ready",
  Contacted: "Contacted",
  Rejected: "Rejected",
  Forwarded: "Forwarded",
  InNegotiation: "InNegotiation",
  Offer: "Offer",
  OfferConfirmed: "OfferConfirmed",
  Live: "Live",
  Lost: "Lost",
  Cooperation: "Cooperation",
  Postponed: "Postponed",
  FollowUp: "FollowUp",
  OfferArchived: "OfferArchived",
  NotInterested: "NotInterested",
  Problem: "Problem",
  Invalid: "Invalid",
  Blacklist: "Blacklist",
  Purchased: "Purchased",
};

export const allStatusArray: [
  MailerRecipients_status,
  ...MailerRecipients_status[],
] = [
  "Ready",
  "Contacted",
  "Rejected",
  "Forwarded",
  "InNegotiation",
  "Offer",
  "OfferConfirmed",
  "Live",
  "Lost",
  "Cooperation",
  "Postponed",
  "FollowUp",
  "OfferArchived",
  "NotInterested",
  "Problem",
  "Invalid",
  "Blacklist",
  "Purchased",
];

export const backlinkTypeOptions: [
  MailerRecipients_backlinkType,
  ...MailerRecipients_backlinkType[],
] = ["Guestpost", "ContentEdit"];

export type ProjectOrCampaign = "Projects" | "ProjectCampaign";
export type SubscriptionType =
  | "OfferSubscriptionCampaign"
  | "OfferSubscriptionProject"
  | "BacklinkIssueSubscriptionCampaign"
  | "BacklinkIssueSubscriptionProject";

export const backlinkStatusOptions: {
  [k in Backlink_status]: Backlink_status;
} = {
  Live: "Live",
  FollowUp: "FollowUp",
  Inconsistent: "Inconsistent",
  Cancelled: "Cancelled",
  Offline: "Offline",
};

export const backlinkStatusArray: [Backlink_status, ...Backlink_status[]] = [
  "Live",
  "FollowUp",
  "Inconsistent",
  "Cancelled",
  "Offline",
];

export const queueStatusOptions: { [k in QueueStatus]: QueueStatus } = {
  Errored: "Errored",
  Prepared: "Prepared",
  Processed: "Processed",
  Queued: "Queued",
};
