export function sleep(n: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, n));
}

export async function asyncFilter<T>(
  arr: Array<T>,
  predicate: (arg: T) => Promise<Boolean>,
) {
  return Promise.all(arr.map(predicate)).then((results) =>
    arr.filter((_v, index) => results[index]),
  );
}
