import type { AppRouter } from "~/server/trpc/routers/_app";
import { createTRPCNuxtClient, httpLink } from "trpc-nuxt/client";
import SuperJSON from "superjson";

export default defineNuxtPlugin(() => {
  const headers = useRequestHeaders();
  const client = createTRPCNuxtClient<AppRouter>({
    transformer: SuperJSON,
    links: [
      httpLink({
        headers: () => headers,
        url: "/api/trpc",
        fetch: (url, options) =>
          fetch(url, { ...options, credentials: "include" }),
      }),
    ],
  });

  return { provide: { client } };
});
