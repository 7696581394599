import { default as archiveTgnhKdtun4Meta } from "/vercel/path0/pages/ai-contacts-filter/archive.vue?macro=true";
import { default as index6sWRd7LURxMeta } from "/vercel/path0/pages/ai-contacts-filter/index.vue?macro=true";
import { default as new5orqQW01yeMeta } from "/vercel/path0/pages/ai-contacts-filter/new.vue?macro=true";
import { default as indexR1uKDsWa1XMeta } from "/vercel/path0/pages/ai-keyword-relevance/index.vue?macro=true";
import { default as newOLhTwW1yzJMeta } from "/vercel/path0/pages/ai-keyword-relevance/new.vue?macro=true";
import { default as data_45update_45toolszFWxQPcj75Meta } from "/vercel/path0/pages/data-update-tools.vue?macro=true";
import { default as index1dEyGWa5drMeta } from "/vercel/path0/pages/dr-update/index.vue?macro=true";
import { default as statusqVnD5gtMRiMeta } from "/vercel/path0/pages/dr-update/status.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as partner_45registrationP7lA9cC9PLMeta } from "/vercel/path0/pages/partner-registration.vue?macro=true";
import { default as blacklist_45domainsLXUfC1l5GDMeta } from "/vercel/path0/pages/projects/[uuid]/blacklist-domains.vue?macro=true";
import { default as blacklist_45domainsogV3dwfkuqMeta } from "/vercel/path0/pages/projects/[uuid]/campaigns/[campaignUuid]/blacklist-domains.vue?macro=true";
import { default as indexmJkjxosdqNMeta } from "/vercel/path0/pages/projects/[uuid]/campaigns/[campaignUuid]/index.vue?macro=true";
import { default as mailertjiFmKpsnYMeta } from "/vercel/path0/pages/projects/[uuid]/campaigns/[campaignUuid]/mailer.vue?macro=true";
import { default as editTpzDtxxJUlMeta } from "/vercel/path0/pages/projects/[uuid]/edit.vue?macro=true";
import { default as indexDFTDGdoBamMeta } from "/vercel/path0/pages/projects/[uuid]/index.vue?macro=true";
import { default as mailserver_45configOV0zvXISmWMeta } from "/vercel/path0/pages/projects/[uuid]/mailserver-config.vue?macro=true";
import { default as report_45integrationRqhl8mDhbVMeta } from "/vercel/path0/pages/projects/[uuid]/report-integration.vue?macro=true";
import { default as shareLjs4CZwv5FMeta } from "/vercel/path0/pages/projects/[uuid]/share.vue?macro=true";
import { default as _91_91projectUuid_93_93VbKIAOj3f5Meta } from "/vercel/path0/pages/projects/archive/campaigns/[[projectUuid]].vue?macro=true";
import { default as indexO6muTp3wj4Meta } from "/vercel/path0/pages/projects/archive/index.vue?macro=true";
import { default as indexssS838kyt4Meta } from "/vercel/path0/pages/projects/index.vue?macro=true";
import { default as index8PqKJnG9GIMeta } from "/vercel/path0/pages/reporting/[uuid]/index.vue?macro=true";
import { default as order_45confirmationIMzJ6fzCXVMeta } from "/vercel/path0/pages/reporting/[uuid]/order-confirmation.vue?macro=true";
import { default as indexaxX3JJVuHVMeta } from "/vercel/path0/pages/reporting/campaign/[uuid]/index.vue?macro=true";
import { default as order_45confirmationmi54PrHw1qMeta } from "/vercel/path0/pages/reporting/campaign/[uuid]/order-confirmation.vue?macro=true";
import { default as _91uuid_93NLkUlWc99jMeta } from "/vercel/path0/pages/tracking-sheet/[uuid].vue?macro=true";
import { default as _91uuid_93IOJbbLI2lkMeta } from "/vercel/path0/pages/tracking-sheet/campaign/[uuid].vue?macro=true";
import { default as indexjFp2T7x3NkMeta } from "/vercel/path0/pages/tracking-sheet/index.vue?macro=true";
import { default as unsubscribeHRFD5K0EoKMeta } from "/vercel/path0/pages/unsubscribe.vue?macro=true";
import { default as indexW17SjYcMXxMeta } from "/vercel/path0/pages/update-tracking/index.vue?macro=true";
import { default as statusSXQivULMcpMeta } from "/vercel/path0/pages/update-tracking/status.vue?macro=true";
import { default as updatePurchasePricesyi4tU4W2MMMeta } from "/vercel/path0/pages/updatePurchasePrices.vue?macro=true";
import { default as visited_45infol5adP1J3pLMeta } from "/vercel/path0/pages/visited-info.vue?macro=true";
export default [
  {
    name: archiveTgnhKdtun4Meta?.name ?? "ai-contacts-filter-archive",
    path: archiveTgnhKdtun4Meta?.path ?? "/ai-contacts-filter/archive",
    meta: archiveTgnhKdtun4Meta || {},
    alias: archiveTgnhKdtun4Meta?.alias || [],
    redirect: archiveTgnhKdtun4Meta?.redirect,
    component: () => import("/vercel/path0/pages/ai-contacts-filter/archive.vue").then(m => m.default || m)
  },
  {
    name: index6sWRd7LURxMeta?.name ?? "ai-contacts-filter",
    path: index6sWRd7LURxMeta?.path ?? "/ai-contacts-filter",
    meta: index6sWRd7LURxMeta || {},
    alias: index6sWRd7LURxMeta?.alias || [],
    redirect: index6sWRd7LURxMeta?.redirect,
    component: () => import("/vercel/path0/pages/ai-contacts-filter/index.vue").then(m => m.default || m)
  },
  {
    name: new5orqQW01yeMeta?.name ?? "ai-contacts-filter-new",
    path: new5orqQW01yeMeta?.path ?? "/ai-contacts-filter/new",
    meta: new5orqQW01yeMeta || {},
    alias: new5orqQW01yeMeta?.alias || [],
    redirect: new5orqQW01yeMeta?.redirect,
    component: () => import("/vercel/path0/pages/ai-contacts-filter/new.vue").then(m => m.default || m)
  },
  {
    name: indexR1uKDsWa1XMeta?.name ?? "ai-keyword-relevance",
    path: indexR1uKDsWa1XMeta?.path ?? "/ai-keyword-relevance",
    meta: indexR1uKDsWa1XMeta || {},
    alias: indexR1uKDsWa1XMeta?.alias || [],
    redirect: indexR1uKDsWa1XMeta?.redirect,
    component: () => import("/vercel/path0/pages/ai-keyword-relevance/index.vue").then(m => m.default || m)
  },
  {
    name: newOLhTwW1yzJMeta?.name ?? "ai-keyword-relevance-new",
    path: newOLhTwW1yzJMeta?.path ?? "/ai-keyword-relevance/new",
    meta: newOLhTwW1yzJMeta || {},
    alias: newOLhTwW1yzJMeta?.alias || [],
    redirect: newOLhTwW1yzJMeta?.redirect,
    component: () => import("/vercel/path0/pages/ai-keyword-relevance/new.vue").then(m => m.default || m)
  },
  {
    name: data_45update_45toolszFWxQPcj75Meta?.name ?? "data-update-tools",
    path: data_45update_45toolszFWxQPcj75Meta?.path ?? "/data-update-tools",
    meta: data_45update_45toolszFWxQPcj75Meta || {},
    alias: data_45update_45toolszFWxQPcj75Meta?.alias || [],
    redirect: data_45update_45toolszFWxQPcj75Meta?.redirect,
    component: () => import("/vercel/path0/pages/data-update-tools.vue").then(m => m.default || m)
  },
  {
    name: index1dEyGWa5drMeta?.name ?? "dr-update",
    path: index1dEyGWa5drMeta?.path ?? "/dr-update",
    meta: index1dEyGWa5drMeta || {},
    alias: index1dEyGWa5drMeta?.alias || [],
    redirect: index1dEyGWa5drMeta?.redirect,
    component: () => import("/vercel/path0/pages/dr-update/index.vue").then(m => m.default || m)
  },
  {
    name: statusqVnD5gtMRiMeta?.name ?? "dr-update-status",
    path: statusqVnD5gtMRiMeta?.path ?? "/dr-update/status",
    meta: statusqVnD5gtMRiMeta || {},
    alias: statusqVnD5gtMRiMeta?.alias || [],
    redirect: statusqVnD5gtMRiMeta?.redirect,
    component: () => import("/vercel/path0/pages/dr-update/status.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: partner_45registrationP7lA9cC9PLMeta?.name ?? "partner-registration",
    path: partner_45registrationP7lA9cC9PLMeta?.path ?? "/partner-registration",
    meta: partner_45registrationP7lA9cC9PLMeta || {},
    alias: partner_45registrationP7lA9cC9PLMeta?.alias || [],
    redirect: partner_45registrationP7lA9cC9PLMeta?.redirect,
    component: () => import("/vercel/path0/pages/partner-registration.vue").then(m => m.default || m)
  },
  {
    name: blacklist_45domainsLXUfC1l5GDMeta?.name ?? "projects-uuid-blacklist-domains",
    path: blacklist_45domainsLXUfC1l5GDMeta?.path ?? "/projects/:uuid()/blacklist-domains",
    meta: blacklist_45domainsLXUfC1l5GDMeta || {},
    alias: blacklist_45domainsLXUfC1l5GDMeta?.alias || [],
    redirect: blacklist_45domainsLXUfC1l5GDMeta?.redirect,
    component: () => import("/vercel/path0/pages/projects/[uuid]/blacklist-domains.vue").then(m => m.default || m)
  },
  {
    name: blacklist_45domainsogV3dwfkuqMeta?.name ?? "projects-uuid-campaigns-campaignUuid-blacklist-domains",
    path: blacklist_45domainsogV3dwfkuqMeta?.path ?? "/projects/:uuid()/campaigns/:campaignUuid()/blacklist-domains",
    meta: blacklist_45domainsogV3dwfkuqMeta || {},
    alias: blacklist_45domainsogV3dwfkuqMeta?.alias || [],
    redirect: blacklist_45domainsogV3dwfkuqMeta?.redirect,
    component: () => import("/vercel/path0/pages/projects/[uuid]/campaigns/[campaignUuid]/blacklist-domains.vue").then(m => m.default || m)
  },
  {
    name: indexmJkjxosdqNMeta?.name ?? "projects-uuid-campaigns-campaignUuid",
    path: indexmJkjxosdqNMeta?.path ?? "/projects/:uuid()/campaigns/:campaignUuid()",
    meta: indexmJkjxosdqNMeta || {},
    alias: indexmJkjxosdqNMeta?.alias || [],
    redirect: indexmJkjxosdqNMeta?.redirect,
    component: () => import("/vercel/path0/pages/projects/[uuid]/campaigns/[campaignUuid]/index.vue").then(m => m.default || m)
  },
  {
    name: mailertjiFmKpsnYMeta?.name ?? "projects-uuid-campaigns-campaignUuid-mailer",
    path: mailertjiFmKpsnYMeta?.path ?? "/projects/:uuid()/campaigns/:campaignUuid()/mailer",
    meta: mailertjiFmKpsnYMeta || {},
    alias: mailertjiFmKpsnYMeta?.alias || [],
    redirect: mailertjiFmKpsnYMeta?.redirect,
    component: () => import("/vercel/path0/pages/projects/[uuid]/campaigns/[campaignUuid]/mailer.vue").then(m => m.default || m)
  },
  {
    name: editTpzDtxxJUlMeta?.name ?? "projects-uuid-edit",
    path: editTpzDtxxJUlMeta?.path ?? "/projects/:uuid()/edit",
    meta: editTpzDtxxJUlMeta || {},
    alias: editTpzDtxxJUlMeta?.alias || [],
    redirect: editTpzDtxxJUlMeta?.redirect,
    component: () => import("/vercel/path0/pages/projects/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexDFTDGdoBamMeta?.name ?? "projects-uuid",
    path: indexDFTDGdoBamMeta?.path ?? "/projects/:uuid()",
    meta: indexDFTDGdoBamMeta || {},
    alias: indexDFTDGdoBamMeta?.alias || [],
    redirect: indexDFTDGdoBamMeta?.redirect,
    component: () => import("/vercel/path0/pages/projects/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: mailserver_45configOV0zvXISmWMeta?.name ?? "projects-uuid-mailserver-config",
    path: mailserver_45configOV0zvXISmWMeta?.path ?? "/projects/:uuid()/mailserver-config",
    meta: mailserver_45configOV0zvXISmWMeta || {},
    alias: mailserver_45configOV0zvXISmWMeta?.alias || [],
    redirect: mailserver_45configOV0zvXISmWMeta?.redirect,
    component: () => import("/vercel/path0/pages/projects/[uuid]/mailserver-config.vue").then(m => m.default || m)
  },
  {
    name: report_45integrationRqhl8mDhbVMeta?.name ?? "projects-uuid-report-integration",
    path: report_45integrationRqhl8mDhbVMeta?.path ?? "/projects/:uuid()/report-integration",
    meta: report_45integrationRqhl8mDhbVMeta || {},
    alias: report_45integrationRqhl8mDhbVMeta?.alias || [],
    redirect: report_45integrationRqhl8mDhbVMeta?.redirect,
    component: () => import("/vercel/path0/pages/projects/[uuid]/report-integration.vue").then(m => m.default || m)
  },
  {
    name: shareLjs4CZwv5FMeta?.name ?? "projects-uuid-share",
    path: shareLjs4CZwv5FMeta?.path ?? "/projects/:uuid()/share",
    meta: shareLjs4CZwv5FMeta || {},
    alias: shareLjs4CZwv5FMeta?.alias || [],
    redirect: shareLjs4CZwv5FMeta?.redirect,
    component: () => import("/vercel/path0/pages/projects/[uuid]/share.vue").then(m => m.default || m)
  },
  {
    name: _91_91projectUuid_93_93VbKIAOj3f5Meta?.name ?? "projects-archive-campaigns-projectUuid",
    path: _91_91projectUuid_93_93VbKIAOj3f5Meta?.path ?? "/projects/archive/campaigns/:projectUuid?",
    meta: _91_91projectUuid_93_93VbKIAOj3f5Meta || {},
    alias: _91_91projectUuid_93_93VbKIAOj3f5Meta?.alias || [],
    redirect: _91_91projectUuid_93_93VbKIAOj3f5Meta?.redirect,
    component: () => import("/vercel/path0/pages/projects/archive/campaigns/[[projectUuid]].vue").then(m => m.default || m)
  },
  {
    name: indexO6muTp3wj4Meta?.name ?? "projects-archive",
    path: indexO6muTp3wj4Meta?.path ?? "/projects/archive",
    meta: indexO6muTp3wj4Meta || {},
    alias: indexO6muTp3wj4Meta?.alias || [],
    redirect: indexO6muTp3wj4Meta?.redirect,
    component: () => import("/vercel/path0/pages/projects/archive/index.vue").then(m => m.default || m)
  },
  {
    name: indexssS838kyt4Meta?.name ?? "projects",
    path: indexssS838kyt4Meta?.path ?? "/projects",
    meta: indexssS838kyt4Meta || {},
    alias: indexssS838kyt4Meta?.alias || [],
    redirect: indexssS838kyt4Meta?.redirect,
    component: () => import("/vercel/path0/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: index8PqKJnG9GIMeta?.name ?? "reporting-uuid",
    path: index8PqKJnG9GIMeta?.path ?? "/reporting/:uuid()",
    meta: index8PqKJnG9GIMeta || {},
    alias: index8PqKJnG9GIMeta?.alias || [],
    redirect: index8PqKJnG9GIMeta?.redirect,
    component: () => import("/vercel/path0/pages/reporting/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmationIMzJ6fzCXVMeta?.name ?? "reporting-uuid-order-confirmation",
    path: order_45confirmationIMzJ6fzCXVMeta?.path ?? "/reporting/:uuid()/order-confirmation",
    meta: order_45confirmationIMzJ6fzCXVMeta || {},
    alias: order_45confirmationIMzJ6fzCXVMeta?.alias || [],
    redirect: order_45confirmationIMzJ6fzCXVMeta?.redirect,
    component: () => import("/vercel/path0/pages/reporting/[uuid]/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexaxX3JJVuHVMeta?.name ?? "reporting-campaign-uuid",
    path: indexaxX3JJVuHVMeta?.path ?? "/reporting/campaign/:uuid()",
    meta: indexaxX3JJVuHVMeta || {},
    alias: indexaxX3JJVuHVMeta?.alias || [],
    redirect: indexaxX3JJVuHVMeta?.redirect,
    component: () => import("/vercel/path0/pages/reporting/campaign/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmationmi54PrHw1qMeta?.name ?? "reporting-campaign-uuid-order-confirmation",
    path: order_45confirmationmi54PrHw1qMeta?.path ?? "/reporting/campaign/:uuid()/order-confirmation",
    meta: order_45confirmationmi54PrHw1qMeta || {},
    alias: order_45confirmationmi54PrHw1qMeta?.alias || [],
    redirect: order_45confirmationmi54PrHw1qMeta?.redirect,
    component: () => import("/vercel/path0/pages/reporting/campaign/[uuid]/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93NLkUlWc99jMeta?.name ?? "tracking-sheet-uuid",
    path: _91uuid_93NLkUlWc99jMeta?.path ?? "/tracking-sheet/:uuid()",
    meta: _91uuid_93NLkUlWc99jMeta || {},
    alias: _91uuid_93NLkUlWc99jMeta?.alias || [],
    redirect: _91uuid_93NLkUlWc99jMeta?.redirect,
    component: () => import("/vercel/path0/pages/tracking-sheet/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93IOJbbLI2lkMeta?.name ?? "tracking-sheet-campaign-uuid",
    path: _91uuid_93IOJbbLI2lkMeta?.path ?? "/tracking-sheet/campaign/:uuid()",
    meta: _91uuid_93IOJbbLI2lkMeta || {},
    alias: _91uuid_93IOJbbLI2lkMeta?.alias || [],
    redirect: _91uuid_93IOJbbLI2lkMeta?.redirect,
    component: () => import("/vercel/path0/pages/tracking-sheet/campaign/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexjFp2T7x3NkMeta?.name ?? "tracking-sheet",
    path: indexjFp2T7x3NkMeta?.path ?? "/tracking-sheet",
    meta: indexjFp2T7x3NkMeta || {},
    alias: indexjFp2T7x3NkMeta?.alias || [],
    redirect: indexjFp2T7x3NkMeta?.redirect,
    component: () => import("/vercel/path0/pages/tracking-sheet/index.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeHRFD5K0EoKMeta?.name ?? "unsubscribe",
    path: unsubscribeHRFD5K0EoKMeta?.path ?? "/unsubscribe",
    meta: unsubscribeHRFD5K0EoKMeta || {},
    alias: unsubscribeHRFD5K0EoKMeta?.alias || [],
    redirect: unsubscribeHRFD5K0EoKMeta?.redirect,
    component: () => import("/vercel/path0/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: indexW17SjYcMXxMeta?.name ?? "update-tracking",
    path: indexW17SjYcMXxMeta?.path ?? "/update-tracking",
    meta: indexW17SjYcMXxMeta || {},
    alias: indexW17SjYcMXxMeta?.alias || [],
    redirect: indexW17SjYcMXxMeta?.redirect,
    component: () => import("/vercel/path0/pages/update-tracking/index.vue").then(m => m.default || m)
  },
  {
    name: statusSXQivULMcpMeta?.name ?? "update-tracking-status",
    path: statusSXQivULMcpMeta?.path ?? "/update-tracking/status",
    meta: statusSXQivULMcpMeta || {},
    alias: statusSXQivULMcpMeta?.alias || [],
    redirect: statusSXQivULMcpMeta?.redirect,
    component: () => import("/vercel/path0/pages/update-tracking/status.vue").then(m => m.default || m)
  },
  {
    name: updatePurchasePricesyi4tU4W2MMMeta?.name ?? "updatePurchasePrices",
    path: updatePurchasePricesyi4tU4W2MMMeta?.path ?? "/updatePurchasePrices",
    meta: updatePurchasePricesyi4tU4W2MMMeta || {},
    alias: updatePurchasePricesyi4tU4W2MMMeta?.alias || [],
    redirect: updatePurchasePricesyi4tU4W2MMMeta?.redirect,
    component: () => import("/vercel/path0/pages/updatePurchasePrices.vue").then(m => m.default || m)
  },
  {
    name: visited_45infol5adP1J3pLMeta?.name ?? "visited-info",
    path: visited_45infol5adP1J3pLMeta?.path ?? "/visited-info",
    meta: visited_45infol5adP1J3pLMeta || {},
    alias: visited_45infol5adP1J3pLMeta?.alias || [],
    redirect: visited_45infol5adP1J3pLMeta?.redirect,
    component: () => import("/vercel/path0/pages/visited-info.vue").then(m => m.default || m)
  }
]