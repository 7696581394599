<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { sleep } from "~/helpers/sleep";
import { visitedStore } from "~/store/visited";
import { uiLanguages } from "~/types/general";

const route = useRoute();
const { locale } = useI18n();

onBeforeMount(() => {
  const storedLang = localStorage.getItem("lang");
  if (storedLang && storedLang in uiLanguages()) {
    locale.value = storedLang;
  }

  const storedColorMode = localStorage.getItem("colorMode");
  if (storedColorMode && storedColorMode in ["system", "dark", "light"]) {
    useColorMode().preference = storedColorMode;
  }
});

async function getTitleEl(): Promise<HTMLTitleElement> {
  const target = document.querySelector("title");
  if (target) return target;
  await sleep(100);
  return getTitleEl();
}

onMounted(async () => {
  const target = await getTitleEl();
  new MutationObserver(() =>
    visitedStore.visitRoute(route.path, document.title),
  ).observe(target, {
    subtree: true,
    characterData: true,
    childList: true,
  });
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UNotifications />
</template>
