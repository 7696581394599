import PrimeVue from "primevue/config";
import Chips from "primevue/chips";
import Tag from "primevue/tag";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, { ripple: true });
  // import and add components as needed
  nuxtApp.vueApp.component("Chips", Chips);
  nuxtApp.vueApp.component("Tag", Tag);
});
